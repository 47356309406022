<template>
  <div>
    <b-table-custom :table-data="dataObjectList"
                    :table-columns="columns"
                    :is-data-loading="isTableDataLoading"
                    @selected-item-onchange="selectedItemOnChange"
    >
      <template #button_event >
        <b-dropdown class="menu-button"
                    variant="primary"
                    size="sm"
        >
          <template #button-content>
            <feather-icon icon="SettingsIcon" class="mr-1"/>
            <span>Action Menu</span>
          </template>
          <b-dropdown-item v-for="(menuItem, index) in actionMenuList"
                           :key="index"
                           @click="openModal(menuItem['name'])"
          >
            <font-awesome-icon :icon="menuItem['icon']" :class="`text-${menuItem['color']}`" />
            <span>{{ menuItem['label'] }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #cell(ip)="{data}">
        <strong class="text-primary">{{ data['value'] }}</strong>
      </template>
      <template #cell(vm_plan_name)="{data}">
        <strong class="text-info">{{ data['value'] }}</strong>
        <br/>
        <span class="text-success">{{ currencyFormat(data['item']['price'], 'en-US', 'USD') }}</span>
      </template>
      <template #cell(transfer_limit)="{data}">
        <span>{{(data['item']['total_rx'] + data['item']['total_tx']).toFixed(2)}} / {{data['item']['transfer_limit']}} GB</span>
        <br/>
        <strong>({{ ((data['item']['total_rx'] + data['item']['total_tx'])/data['item']['transfer_limit'] * 100).toFixed(2) }}%)</strong>
      </template>
      <template #cell(start_date)="{data}">
        <span>{{ convertToISODate(data['value']) }}</span>
      </template>
      <template #cell(end_date)="{data}">
        <span>{{ convertToISODate(data['value']) }}</span>
      </template>
    </b-table-custom>

    <custom-modal
        id="action-menu-modal"
        ref="action-menu-modal"
        :title="currentModal['title']"
        :ok-title="currentModal['okButton']"
        :cancel-title="currentModal['cancelButton']"
        :variant="currentModal['variant']"
        @ok="currentModal['okFunction']"
        :is-manual-close="currentModal['isManualClose']"
    >
      <b-card-text v-if="modalName === 'modal-assign-proxy'">
        <b-form-group>
          <label>Gán <strong class="text-danger">{{ dataObjectSelectedList.length }}</strong> proxy dưới đây cho người dùng:</label>
          <item-list :items="dataObjectSelectedList">
            <template #item-label="{data}">
              {{ data['ip'] }} - <span class="text-info">{{ data['vm_plan_name'] }}</span> <span class="small">({{ data['user_remark'] }})</span>
            </template>
          </item-list>
        </b-form-group>
        <custom-select v-model="objectAssign.plan_id"
                       :options="planList"
                       label="Plan"
                       reduce="id"
                       display-field="name"
        >
          <template #option="{item}">
            <span><small><strong>{{ item['name'] }}</strong> (<span class="text-success">{{ currencyFormat(item['selling_price']) }}</span>)</small></span>
          </template>
          <template #selected-option="{item}">
            <span><small><strong>{{ item['name'] }}</strong> (<span class="text-success">{{ currencyFormat(item['selling_price']) }}</span>)</small></span>
          </template>
        </custom-select>
        <user-list v-model="objectAssign['owner_id']"
                   label="Chọn người dùng"
        />
        <b-form-group>
          <label>Note</label>
          <b-form-textarea
              rows="2"
              v-model="objectAssign['note']"
          />
        </b-form-group>
        <b-form-group>
          <label>Admin Note</label>
          <b-form-textarea
              rows="2"
              v-model="objectAssign['admin_note']"
          />
        </b-form-group>
        <b-row>
          <b-col sm="6">
            <date-time-picker
                :is-show-add-time="true"
                :is-show-minus-time="true"
                v-model="objectAssign['start_date']" label="Start Date"
            />
          </b-col>
          <b-col sm="6">
            <date-time-picker
                :is-show-add-time="true"
                :is-show-minus-time="true"
                v-model="objectAssign['end_date']" label="End Date"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </custom-modal>
  </div>
</template>

<script>

import InputHelper from "@/mixins/utils/inputHelper";
import DataRequester from "@/mixins/utils/dataRequester";
import DataObjectList from "@/mixins/utils/dataObjectList";
import NotificationHelper from "@/mixins/notificationHelper";
import useJwt from '@/auth/jwt/useJwt'

import BTableCustom from "@/views/components/table/BTableCustom.vue";
import CustomModal from "@/views/components/CustomModal.vue";
import CustomSelect from "@/views/components/form/CustomSelect.vue";
import DateTimePicker from "@/views/components/form/DateTimePicker.vue";
import ItemList from "@/views/components/ItemList.vue";
import CustomInput from "@/views/components/form/CustomInput.vue";
import UserList from "@/views/components/UserList.vue";
import proxyService from "@/mixins/utils/axios/proxy.service";
import userService from "@/mixins/utils/axios/user.service";

export default {
  name: "Storage",
  mixins: [InputHelper, DataRequester, DataObjectList, NotificationHelper],
  components: {
    CustomInput,
    ItemList,
    DateTimePicker,
    CustomSelect,
    CustomModal,
    BTableCustom,
    UserList
  },
  computed: {
    currentModal: function () {
      switch(this.modalName) {
        case 'modal-assign-proxy':
          return {
            title: 'Assign Proxy',
            variant: 'danger',
            isManualClose: true,
            okFunction: this.assignProxy
          }
        case 'renew-proxy':
          return {
            title: 'Renew Proxy',
            variant: 'success',
            okFunction: function () {
              console.log('default')
            }
          }
        default:
          return {
            title: '',
            variant: 'primary',
            okButton: 'OK',
            cancelButton: 'Cancel',
            okFunction: function () {
              console.log('default')
            }
          }
      }
    }
  },
  data() {
    return {
      columns: [
        {key: 'ip', 'label': 'IP'},
        {key: 'vm_plan_name', 'label': 'Plan'},
        {key: 'transfer_limit', 'label': 'Transfer Limit'},
        {key: 'start_date', 'label': 'Start Date'},
        {key: 'end_date', 'label': 'End Date'},
        {key: 'user_remark', 'label': 'Remark'},
        {key: 'payment_status', 'label': 'Payment Status'},
      ],
      isTableDataLoading: false,
      modalName: null,
      actionMenuList: [
        {
          name: 'modal-assign-proxy',
          label: 'Gán Proxy',
          icon: 'fa-solid fa-plus',
          color: 'success'
        },
        // {
        //   name: 'renew-proxy',
        //   label: 'Tạo hoá đơn gia hạn',
        //   icon: 'fa-solid fa-calendar-plus',
        //   color: 'info'
        // }
      ],
      userList: [],
      planList: [],
      objectAssign: {
        start_date: null,
        end_date: null
      }
    };
  },
  created() {
    let self = this
    this.getProxyListStorage()

    this.runApi(userService.listAllUsers(), function (result) {
      self.userList = result.data
    })
    this.runApi(proxyService.getProxyActivePlanList(), function (result) {
      self.planList = result.data
    })
  },
  methods: {
    selectedItemOnChange(selectedItems) {
      this.dataObjectSelectedList = selectedItems
    },
    openModal(modalName) {
      this.modalName = modalName

      if (this.dataObjectSelectedList.length === 0) {
        this.popupError('Bạn cần chọn ít nhất một giá trị để thực hiện thao tác')
        return
      }

      if (modalName === 'renew-proxy') {
        let planUnique = [...new Set(this.dataObjectSelectedList.map(item => item['vm_plan_name']))]
        if (planUnique.length > 1) {
          this.popupError('Không thể tạo hoá đơn gia hạn cho các Proxy có các plan khác nhau')
          return
        }
      }

      this.$refs['action-menu-modal'].show()
    },
    closeModal() {
      this.$refs['action-menu-modal'].hide()
    },

    getProxyListStorage() {
      let self = this
      this.isTableDataLoading = true
      this.runApi(proxyService.proxyListStorage(), function (result) {
        self.dataObjectList = result.data
      }, undefined, function () {
        self.isTableDataLoading = false
      })
    },
    assignProxy() {
      let self = this
      this.runApi(proxyService.assignProxy({
        owner_id: self.objectAssign['owner_id'],
        plan_id: self.objectAssign['plan_id'],
        note: self.objectAssign['note'],
        admin_note: self.objectAssign['admin_note'] ,
        proxies: self.dataObjectSelectedList.map(item => {
          return {
            lev_service_id: item['id'],
            ip: item['ip'],
            start_date: self.objectAssign['start_date'] || item['start_date'],
            end_date: self.objectAssign['end_date'] || item['end_date'],
          }
        })
      }), function () {
        self.removeRowDataByIds(self.dataObjectSelectedList.map(x => x['id']))
      }, undefined, function () {
        self.closeModal()
      }, true)
    }
  },
}
</script>
